<template >
    <div v-if="!preloaderSet && $route.meta.preloader == true" class="preloader" id="preloader" ref="preloader" >
    <div class="preloader__container">
        <div class="preloader__titles">
            <div class="preloader__title preloader__title--first">hello</div>
            <div class="preloader__title preloader__title--second">we</div>
            <div class="preloader__title preloader__title--third">are</div>
            <div class="preloader__title preloader__title--fourth">
                <div class="preloader__title-logo">adits</div>
                <div class="preloader__title-dot"></div>
            </div>
        </div>
        <div class="preloader__columns">
            <div class="preloader__column preloader__column--first"></div>
            <div class="preloader__column preloader__column--second"></div>
            <div class="preloader__column preloader__column--third"></div>
            <div class="preloader__column preloader__column--fourth"></div>
        </div>
        <div class="preloader__letters">
            <div class="preloader__word">
                <div class="preloader__letter" style="animation-delay:4.4s;">t</div>
                <div class="preloader__letter" style="animation-delay:4.5s;">h</div>
                <div class="preloader__letter" style="animation-delay:4.6s;">i</div>
                <div class="preloader__letter" style="animation-delay:4.7s;">n</div>
                <div class="preloader__letter" style="animation-delay:4.8s;">k</div>
            </div>
            <div class="preloader__word">
                <div class="preloader__letter" style="animation-delay:4.4s;">b</div>
                <div class="preloader__letter" style="animation-delay:4.5s;">i</div>
                <div class="preloader__letter letter" style="animation-delay:4.6s;">g</div>
                <div class="preloader__letter" style="animation-delay:4.7s;">f</div>
                <div class="preloader__letter" style="animation-delay:4.8s;">o</div>
                <div class="preloader__letter" style="animation-delay:4.9s;">r</div>
            </div>
            <div class="preloader__word">
                <div class="preloader__letter" style="animation-delay:4.4s;">b</div>
                <div class="preloader__letter" style="animation-delay:4.5s;">u</div>
                <div class="preloader__letter" style="animation-delay:4.6s;">s</div>
                <div class="preloader__letter" style="animation-delay:4.7s;">i</div>
                <div class="preloader__letter" style="animation-delay:4.8s;">n</div>
                <div class="preloader__letter" style="animation-delay:4.9s;">e</div>
                <div class="preloader__letter" style="animation-delay:5s;">s</div>
                <div class="preloader__letter" style="animation-delay:5.1s;">s</div>
            </div>
        </div>
    </div>
</div>
<Header></Header>
<router-view />
<Footer v-if="$route.meta.footer === true"></Footer>
<div v-if="$route.meta.cursor === true" class="cursor" ></div>
<div class="drag-cursor">
    <p>drag</p>
</div>

<!-- <Cookies></Cookies> -->
</template>

<script>
import Header from './components/Header.vue'
// import Cookies from './components/Cookies.vue'
import Footer from './components/Footer.vue'
export default {
    components: {
        Header,
        Footer,
        // Cookies
    },
    data() {
        return {
            preloaderSet: true,
            desktop: true,
            links: []
        }
    },
    methods: {
        moveCursor(e) {
            if (this.desktop) {
                let innerCursor = document.querySelector(".cursor");
                let dragCursor = document.querySelector(".drag-cursor");
                let x = e.clientX;
                let y = e.clientY;
                innerCursor.style.left = `${x}px`;
                innerCursor.style.top = `${y}px`;
                dragCursor.style.left = `${x}px`;
                dragCursor.style.top = `${y}px`;
            } else {
                return
            }
        },
        windowResize() {
            if (window.innerWidth <= 1114) {
                this.desktop = false
            } else {
                this.desktop = true
            }
        },
        selectCursorHoverElements() {
            let innerCursor = document.querySelector(".cursor");
            this.links = Array.from(document.querySelectorAll(".cursor-hover-effect"));
            this.links.forEach((link) => {
                link.addEventListener("mouseover", () => {
                    innerCursor.classList.add("grow");
                });
                link.addEventListener("mouseleave", () => {
                    innerCursor.classList.remove("grow");
                });
            });
        }
    },
    mounted() {
        this.windowResize()
        window.addEventListener("resize", this.windowResize) 
         
        window.addEventListener("load", () => {  
            if(this.$route.meta.preloader){
                this.preloaderSet= false;
                setTimeout(() => {
                    document.body.style.overflow = 'hidden';
                    var preloaderElement = document.getElementById('preloader'); 
    
                    const lastLetter = preloaderElement.querySelector('.preloader__column--fourth'); 
                    lastLetter.addEventListener('animationend', () => {   
                        setTimeout(() => {
                            this.preloaderSet = true; 
                            document.body.style.overflow = 'auto';
                        }, 1500)
                    }); 
                }, 500)            
            } 
        })
             
             
        document.addEventListener("mousemove", this.moveCursor);

        this.$nextTick(() => {
            this.selectCursorHoverElements();
        });
    },
    unmounted() {
        window.removeEventListener("resize", this.windowResize);
    },
}
</script>

<style scoped>
/* .preloader{
    animation: disappear 5.1s linear;
}

@keyframes disappear {
        from {
            opacity: 1; 
        }
        to {
            opacity: 0;
            display: none !important;
        }
    } */


.letter {
    margin-right: 0.8em;
}

.logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
}

.logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vue:hover {
    filter: drop-shadow(0 0 2em #42b883aa);
}

.cursor {
    position: fixed;
    left: 10px;
    width: 18px;
    height: 18px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    mix-blend-mode: difference;
    border-radius: 50%;
    pointer-events: none;
    transition: width 0.3s, height 0.3s;
    z-index: 1000;
}

.cursor2{
    background-color: black;
    mix-blend-mode: difference;
}

.cursor.grow {
    width: 30px;
    height: 30px;
    transition: width 0.3s, height 0.3s;
}

.drag-cursor {
    position: fixed;
    left: 10px;
    width: 70px;
    height: 70px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    pointer-events: none;
    transition: opacity 0.3s;
    z-index: 1000;
    opacity: 0;
    text-align: center;
}

.drag-cursor.larger {
    width: 80px;
    height: 80px;
    transition: opacity 0.5s;
}

.drag-cursor p {
    font-size: 1rem;
}

.drag-cursor::after {
    position: absolute;
    right: -15px;
    transition: opacity 0.3s;
    background-image: url('@/assets/right.svg');
    background-size: 15px 15px;
    display: inline-block;
    width: 15px;
    height: 15px;
    content: "";
}

.drag-cursor::before {
    position: absolute;
    left: -15px;
    background-image: url('@/assets/left.svg');
    transition: opacity 0.3s;
    background-size: 15px 15px;
    display: inline-block;
    width: 15px;
    height: 15px;
    content: "";
}

@media (max-width:1024px) {
    .cursor,
    .drag-cursor{
        display: none;
    }
}
</style>
